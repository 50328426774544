<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('certificate')"
                        :isNewButton="checkPermission('certificate_store')"
                        :isColumns="true"
                        @new-button-click="createFormShow()"
                        @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('certificate')"
                              :isNewButton="checkPermission('certificate_store')"
                              :isColumns="true"
                              @new-button-click="createFormShow()"
                              @filter-div-status="datatable.filterStatus = $event"
                >
                    <template v-slot:columns>
                        <div class="mb-1"
                             v-for="(column, key) in datatable.columns"
                             v-bind:key="key">
                            <b-form-checkbox v-model="column.hidden"
                                             :id="'checkbox-' + key"
                                             :name="'checkbox-' + key"
                                             :value="false"
                                             :unchecked-value="true"
                                             v-if="column.field != 'buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <!-- Datatable -->
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6">
                        <b-form-group :label="$t('name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>

                </b-row>

            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>

            <!-- Create Form -->
            <CommonModal ref="createFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <CreateForm @createFormSuccess="createFormSuccess"
                                v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>
            <CommonModal ref="addFormModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('add_course').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <CertificateCourses :certificate_id="formId" :certificate_name="certificateName"  @createFormSuccess="addFormSuccess"
                                v-if="formProcess=='add_course'"
                    />
                </template>
            </CommonModal>

            <!-- Update Form-->
            <CommonModal ref="updateFormModal" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t('edit').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <UpdateForm :formId="formId"
                                @updateFormSuccess="updateFormSuccess"
                                v-if="formProcess=='update'"/>
                </template>
            </CommonModal>



        </app-layout>
    </div>
</template>

<script>
// Template
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';

// Components
import DatatableFilter from '@/components/datatable/DatatableFilter';
import Datatable from '@/components/datatable/Datatable';
import CommonModal from '@/components/elements/CommonModal';

// Pages
import CreateForm from './CreateForm';
import UpdateForm from './UpdateForm';

// Services
import CertificateService from "@/services/CertificateService";
// Others
import qs from 'qs';
import CertificateCourses from "@/modules/certificate/pages/CertificateCourses";


export default {
    components: {
        CertificateCourses,
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        CommonModal,

        CreateForm,
        UpdateForm
    },
    metaInfo() {
        return {
            title: this.$t('certificate')
        };
    },
    data() {
        return {
            datatable: {
                certificateName:null,
                certificate_id:null,
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [          {
                            text: this.$t('show'),
                            class: 'ri-search-eye-line',
                            permission: "certificate_show",
                            callback: (row) => {
                                this.showForm(row.id);
                            }
                        },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: 'certificate_update',
                                callback: (row) => {
                                    this.updateFormShow(row.id);
                                }
                            },
                            {
                                text: this.$t('add_course'),
                                class: 'ri-edit-box-line',
                                permission: "certificatecourse_index",
                                callback: (row) => {
                                    this.addFormShow(row.id,row.name);
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: 'certificate_delete',
                                callback: (row) => {
                                    this.deleteForm(row.id);
                                }
                            },

                        ]
                    },
                    {
                        label: this.$t('id'),
                        field: 'id',
                        sortable: true,
                    },
                    {
                        label: this.$t('name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.$t('name_en'),
                        field: 'name_en',
                        sortable: false,
                    },
                    {
                        label: this.$t('contents'),
                        field: 'contents',
                        sortable: false,
                    },
                    {
                        label: this.$t('outputs'),
                        field: 'outputs',
                        sortable: false,
                    },
                    {
                        label: this.$t('min_pass_count'),
                        field: 'min_pass_count',
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: '-id',
                    page: 1,
                    limit: 20
                }
            },
            formProcess: null,
            formId: null,
            certificateId:null,
            userId: null
        }
    },
    created() {
        this.filterSet();
        this.formClear();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {

            };
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden = this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : '';
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };

            return CertificateService.getAll(config)
                .then((response) => {
                        const data = response.data.data
                        let realdata=[];
                        data.map((item) => {
                            if (item.type=="edr"){
                                realdata.push(item)
                                this.datatable.rows = realdata;

                            }
                        }

                    )
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },

        // Clear
        formClear() {
            this.formId = null;
            this.formProcess = null;
        },

        // Create
        createFormShow() {
            this.formId = null;
            this.formProcess = 'create';
            this.$refs.createFormModal.$refs.commonModal.show();
        },
        createFormSuccess() {
            this.$refs.createFormModal.$refs.commonModal.hide();
            this.getRows();
            this.formClear();
        },
        addFormShow(id,name) {
            this.formId = id;
            this.certificateName=name
            this.formProcess = 'add_course';
            this.$refs.addFormModal.$refs.commonModal.show();
        },
        addFormSuccess() {
            this.$refs.addFormModal.$refs.commonModal.hide();
            this.getRows();
            this.formClear();
        },

        // Update
        updateFormShow(id) {
            this.formId = id;
            this.formProcess = 'update';
            this.$refs.updateFormModal.$refs.commonModal.show();
        },
        updateFormSuccess() {
            this.$refs.updateFormModal.$refs.commonModal.hide();
            this.getRows();
            this.formClear();
        },


        // Delete
        deleteForm(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        CertificateService.deleteCertifica(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRows();
                                this.formClear();
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message));
                            })
                    }
                })
        }
    }
}
</script>

