<template>
    <div>
            <ValidationObserver ref="formModalValidate">
                    <b-row class="mt-2">
                        <b-col cols="12" md="12">
                            <b-form-group class="" :label="$t('name')">
                                <ValidationProvider
                                    name="name"
                                    rules="required"
                                    v-slot="{ valid, errors }"
                                >
                                    <b-input-group class="mb-3" prepend="TR">
                                        <b-form-input
                                            v-model="formData.name"
                                            :class="errors[0] ? 'is-invalid' : ''"
                                        />
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                        class="mb-2"
                                    />
                                </ValidationProvider>
                                <ValidationProvider
                                    name="name_en"
                                    v-slot="{ valid, errors }"
                                >
                                    <b-input-group prepend="EN">
                                        <b-form-input
                                            v-model="formData.name_en"
                                            :class="errors[0] ? 'is-invalid' : ''"
                                        />
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        v-if="errors[0]"
                                        v-html="errors[0]"
                                    />
                                </ValidationProvider>
                            </b-form-group>
                            <ValidationProvider name="contents"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('contents')">
                                    <b-form-input v-model="formData.contents"
                                                  :placeholder="$t('contents')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="outputs"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('outputs')">
                                    <b-form-input v-model="formData.outputs"
                                                  :placeholder="$t('outputs')"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="min_pass_count"
                                                rules="required"
                                                v-slot="{ valid, errors }">
                                <b-form-group :label="$t('min_pass_count')">
                                    <b-form-input type="number" v-model="formData.min_pass_count"
                                                  :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="d-flex justify-content-center mt-3 mb-3">
                                <b-button variant="primary"
                                          @click="updateForm"
                                          :disabled="formLoading">
                                    {{ $t('save') }}
                                </b-button>
                            </div>
                        </b-col>

                    </b-row>
            </ValidationObserver>
    </div>
</template>

<script>
// Components

// Services
import CertificateService from "@/services/CertificateService";
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"


export default {
    components: {

        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            type:"edr",
            formData: {
                name: null,
                name_en: null,
                contents:null,
                outputs:null,
                min_pass_count:null
            },
            formLoading: false,
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            CertificateService.get(id)
                .then(response => {
                    let data = response.data.data;
                    this.formData = {
                        name: data.name,
                        name_en:  data.name_en,
                        contents: data.contents,
                        outputs: data.outputs,
                        min_pass_count: data.min_pass_count
                    }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },

        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                this.formData.type= this.type
                CertificateService.update(this.formId, this.formData)
                    .then(response => {
                        this.$emit('updateFormSuccess', true);
                        this.$toast.success(this.$t('api.' + response.data.message));
                    })
                    .catch(e => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })
            }
        }
    }
}
</script>

